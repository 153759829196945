body {
    padding: 0;
    margin: 0;
    background: black;
    overflow: hidden;

    &.hide-mouse {
        cursor: none;
    }
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(/woff/OpenSansLatin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(/woff/OpenSansItalicLatin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(/woff/OpenSansBoldLatin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


#root {
    .editor, .player {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
    }
    .player {
        background: black;
    }
    .editor {
        background: darkgray;
    }
}

.controls {
    position: absolute;
    top: calc(1080px/2 + 16px);
    width: calc(1920px/2 - 16px);

    .position {
        width: 100%;
        display: flex;
        margin-left: 8px;
        #current {
            width: 72px;
            margin-left: 8px;
            margin-right: 8px;
        }
    }
    .options {
        margin: 8px;
    }
}

.player {
    background: black;
    width: 1920px;
    height: 1080px;
    transform-origin: top left;

    position: absolute;

    .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
    }
    .title {
        position: absolute;
        color: white;
        font-family: "Arial";
        font-weight: bold;
        font-size: 48px;
        line-height: 64px;
        height: calc(100% - 48px);
        width: calc(100% - 48px);
        padding: 24px;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-shadow: 2px 2px 1px black;
    }
    .text {
        position: absolute;
        color: white;
        font-family: "Arial";
        font-weight: bold;
        font-size: 32px;
        line-height: 64px;
        height: calc(100% - 48px);
        width: calc(100% - 48px);
        padding: 24px;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-shadow: 2px 2px 1px black;
    }
    .image {
        position: absolute;
        display: flex;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .video {
        position: absolute;
        height: 100%;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .video video {
        height: 100%;
        pointer-events: none;
    }

    .crop {
        width: 1920px;
        height: 1080px;
        //background: black;
        overflow: hidden;
        //display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .crop img {
        -moz-user-select: none;
        pointer-events: none;
    }
    .audio {
        display: none
    }
}
