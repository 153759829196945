$track-height: 32px;

.timeline-box {
    .label {
        position: absolute;
        bottom: 22px;
        width: 64px;
        left: 0px;
        .position {
            height: 32px;
            margin-bottom: 15px;
            input {
                width: 32px;
            }
        }
        .tracks {
            .track {
                padding-left: 1px;
                margin-top: 5px;
                height: $track-height;
                display: flex;
                align-items: center;
            }
            .seek {
                height: 15px;
            }
        }
    }
    .timeline {
        position: absolute;
        bottom: 22px;
        overflow-y: scroll;
        left: 16px;
        width: 100%;
        .position {
            background: lightyellow;
            height: 8px;

            .playhead {
                position: absolute;
                top: 0;
                width: 2px;
                height: 8px;
                background: red;
            }
        }
        .tracks {
            .track {
                background: gray;
                height: $track-height;
                position: relative;
                margin-bottom: 5px;

                .clip {
                    position: absolute;
                    border: 1px solid black;
                    background: lightgray;
                    height: calc(100% - 2px);
                    overflow: hidden;
                    &.video {
                        background: #33CEFF;
                    }
                    &.audio {
                        background: yellow;
                    }
                    &.overlapping {
                        border-left: solid 2px red;
                    }
                    &.image {
                        background: pink;
                    }

                    &.selected {
                        background: green;
                    }
                }
            }
        }

    }
}

.yaml-editor {
    position: absolute;
    right: 8px;
    width: calc(100% - 1920px*0.5 - 16px);
    top: 8px;

    textarea {
        width: 100%;
        height: 600px;

        &.invalid {
            border: 1px solid red;
        }
    }

}
.entry-details {
    position: absolute;
    right: 8px;
    width: calc(100% - 1920px*0.5 - 16px);
    overflow-y: auto;
    top: 8px;

    textarea {
        width: 100%;
        height: 140px;
    }
    input[type=text] {
        width: 100%;
    }

    .details-navigation {
        float: right;
    }

}

.position {
    width: 800px;
}

.zoom {
    position: absolute;
    right: 8px;
    width: 512px;
    bottom: 0;
    height: 26px;
}

.zoom, .position {
    input[type=range] {
      -webkit-appearance: none;
      margin: 8px 0;
      width: 100%;
      background:inherit;
    }
    input[type=range]:focus {
      outline: none;
    }
    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 8px;
      cursor: pointer;
      animate: 0.2s;
      box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      background: #3071a9;
      border-radius: 1.3px;
      border: 0.2px solid #010101;
    }
    input[type=range]::-webkit-slider-thumb {
      box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      border: 1px solid #000000;
      height: 16px;
      width: 16px;
      border-radius: 3px;
      background: #ffffff;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -4px;
    }
    input[type=range]:focus::-webkit-slider-runnable-track {
      background: #367ebd;
    }
    input[type=range]::-moz-range-track {
      width: 100%;
      height: 8px;
      cursor: pointer;
      animate: 0.2s;
      box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      background: #3071a9;
      border-radius: 1.3px;
      border: 0.2px solid #010101;
    }
    input[type=range]::-moz-range-thumb {
      box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      border: 1px solid #000000;
      height: 16px;
      width: 16px;
      border-radius: 3px;
      background: #ffffff;
      cursor: pointer;
    }
    input[type=range]::-ms-track {
      width: 100%;
      height: 8px;
      cursor: pointer;
      animate: 0.2s;
      background: transparent;
      border-color: transparent;
      border-width: 16px 0;
      color: transparent;
    }
    input[type=range]::-ms-fill-lower {
      background: #2a6495;
      border: 0.2px solid #010101;
      border-radius: 2.6px;
      box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    }
    input[type=range]::-ms-fill-upper {
      background: #3071a9;
      border: 0.2px solid #010101;
      border-radius: 2.6px;
      box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    }
    input[type=range]::-ms-thumb {
      box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      border: 1px solid #000000;
      height: 16px;
      width: 16px;
      border-radius: 3px;
      background: #ffffff;
      cursor: pointer;
    }
    input[type=range]:focus::-ms-fill-lower {
      background: #3071a9;
    }
    input[type=range]:focus::-ms-fill-upper {
      background: #367ebd;
    }
}

