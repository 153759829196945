.context {
    color: rgb(240, 240, 240);
    font-family: "Open Sans", sans-serif;
    width: 1920px;
    height: 1080px;
    transition: background 0.2s ease-in-out;

    a, a:visited {
        text-decoration: none;
        color: rgb(128, 128, 255);
    }
    a:hover {
        color: rgb(192, 192, 255);
    }

    .ref-control {
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
        display: flex;
        font-size: 32px;
        div {
            padding: 4px;
        }
        img {
            width: 32px;
            height: 32px;
            filter: invert(1);
        }
        img:hover {
            filter: invert(1) brightness(0.9);
        }
    }
    .fullscreen {
        position: absolute;
        top: 4px;
        right: 4px;
        width: 52px;
        height: 52px;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            filter: invert(1) brightness(0.9);
        }
    }

    &.play {
        background: rgba(16, 16, 16, 0);
        transition: opacity 0.2s ease-in-out;

        .control {
            position: absolute;
            right: 0;
            top: 0;
            height: calc(100% - 128px);
            width: 100%;
            display: flex;
            align-items: center;
        }
        .control div {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 30%;
            display: flex;
            align-items: center;
        }
        .control img {
            width: 256px;
            margin: auto;
            opacity: 0.8;
        }
        .control img:hover {
            opacity: 1;
        }
    }

    &.with-refs {
        background: rgba(16, 16, 16, 0.82);

        .control {
            position: absolute;
            right: 0;
            top: 0;
            height: calc(100% - 128px);
            width: 30%;
            display: flex;
            align-items: center;
        }
        .control img {
            width: 50%;
            margin: auto;
            opacity: 0.8;
        }
    }
    .control img:hover {
        opacity: 1;
        cursor: pointer;
    }

    .refs {
        padding-left: 16px;
        padding-top: 16px;
    }

    .ref {
        display: flex;
        flex-direction: row;
        width: 70%;
        font-size: 20px;

        img {
            flex: 1;
            margin: 8px;
            margin-right: 16px;
            margin-bottom: 16px;
            width: 180px;
        }
        img:hover {
            filter: brightness(1.1);
        }
        .details {
            flex: 1 1 auto;
            margin: 4px;
            display: flex;
            flex-direction: column;
        }
        .details h1 {
            margin: 0;
            padding: 0;
            font-size: 34px;

            a, a:visited, a:active, a:hover {
                outline: none;
                color: inherit;
            }
        }

        a.frame,
        a.frame:visited {
            color: rgb(240, 240, 240);
        }

        .details .frames {
            display: flex;
            flex-direction: row;
            font-weight: bold;
        }

        .frame {
            text-align: center;
            font-size: 16px;
            font-weight: bold;
        }
        .note {
            width: calc(100% - 8px);
            padding-bottom: 8px;
        }

        .info {
        }

        a.document {
            max-height: calc(200px * 1.414);
            padding-bottom: 8px;
            overflow: hidden;
        }
        .external {
            margin-left: 216px;
        }

        &.collection {
            img {
                border-radius: 24px;
            }
        }
    }

    .nav {
        //background: rgba(16, 16, 16, 0.8);

        position: absolute;
        margin: 0;
        margin-left: 16px;
        margin-right: 16px;
        bottom: -24px;
        left: 0;
        text-align: center;
        height: 128px;
        width: calc(100% - 32px);
        display: flex;
        align-items: center;

        a, a:visited, a:active, a:hover {
            outline: none;
            color: inherit;
        }

        .play {
            transform: scale(0.5);
            transform-origin: bottom right;
        }

        .nav-item {
            padding: 4px;
            width: 100%;
        }

        .nav-item:hover {
            cursor: pointer;
        }
        .nav-item img {
            max-width: 100%;
            max-height: 80px;
        }
        .nav-item img:hover {
            filter: brightness(1.1);
        }

        .nav-item img.list {
            width: 256px;
            height: 256px;
            filter: invert(1);
        }

        .nav-item .nav-title {
          visibility: hidden;
          position: absolute;
          top: 0;
        }
        .nav-item:last-child .nav-title {
          right: 8px;
        }
        .nav-item:first-child .nav-title {
          left: 8px;
        }
        .nav-item:hover .nav-title {
          visibility: visible;
        }
    }
    $seek-bar-height: 48px;

    .seek {
        background: rgba(0, 0, 0, 0.8);
        position: absolute;
        margin: 0;
        bottom: 0;
        width: 100%;
        height: $seek-bar-height;
        display: flex;

        .index {
            width: $seek-bar-height;
        }

        .index:hover {
            cursor: pointer;
        }
        .index img {
            max-width: 100%;
            max-height: $seek-bar-height;
        }
        .index img:hover {
            filter: brightness(1.1);
        }

        .index img.list {
            width: $seek-bar-height;
            height: $seek-bar-height;
            filter: invert(1);
        }

        .index .nav-title {
          visibility: hidden;
          position: absolute;
          top: -32px;
        }
        .index:hover .nav-title {
          visibility: visible;
        }
        .position {
            width: 96px;
            height: 100%;
            display: flex;
            text-align: center;
            align-content: center;
            div {
                margin: auto;
                font-size: 18px;
            }
        }
        .playpause {
            height: 100%;
            width: 64px;
            display: flex;
            text-align: center;
            align-content: center;
            cursor: pointer;
            img {
                width: auto;
                height: 100%;
                margin: auto;
            }
        }
        .seekbar {
            //background-color: rgba(16,16,16, 0.8);
            width: calc(100% - 3 * 48px - 96px);
            height: 100%;
            cursor: pointer;
            .chapter {
                position: absolute;
                width: 256px;
                height: 128px;
                div {
                    text-align: center;
                    margin: auto;
                    font-size: 18px;
                    white-space: nowrap;
                }
                img {
                    width: auto;
                    height: 100%;
                }
            }
            .target {
                position: absolute;
                width: 82px;
                height: 100%;
                display: flex;
                text-align: center;
                align-content: center;
                div {
                    margin: auto;
                    font-size: 18px;
                }
            }
            .played {
                background-color: rgb(64,64,64);
                height: calc(100% - 8px);
                margin: 4px 0 4px 0;
            }
        }
        .fullscreen {
            width: $seek-bar-height;
            height: $seek-bar-height;
            position: revert;
            top: revert;
            right: revert;
        }
    }
}

.overlay {
    .seeking {
        width: 100%;
        height: 100%;
        background: rgba(16, 16, 16, 0.5);
        display: flex;
        align-items: center;

        .loading-ring {
            display: inline-block;
            position: relative;
            width: 128px;
            height: 128px;
            margin: auto;
        }
        .loading-ring div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 102px;
            height: 102px;
            margin: 12px;
            border: 12px solid #fff;
            border-radius: 50%;
            animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #fff transparent transparent transparent;
        }
        .loading-ring div:nth-child(1) {
            animation-delay: -0.45s;
        }
        .loading-ring div:nth-child(2) {
            animation-delay: -0.3s;
        }
        .loading-ring div:nth-child(3) {
            animation-delay: -0.15s;
        }
        @keyframes loading-ring {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

    }
}
